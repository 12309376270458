import { useEffect, CSSProperties, FC } from "react";
import { CalendarCheck, CircleCheck } from "lucide-react";

import { clsxMerge } from "shared/lib/helpers";
import { useApi } from "shared/lib/hooks";
import { PropsWithClassNameI } from "shared/lib/interfaces/ui";
import { Button } from "shared/ui/shadcn/button";
import { LoadingSpinner } from "shared/ui/spinners/loading-spinner";

import { APII, glencocoClientAPI } from "@/api/glencoco";
import Router from "@/helpers/router";

interface CatchupTodosButtonPropsI extends PropsWithClassNameI {
  isLoading?: boolean;
}

const todoCountFetcher = (API: APII) => API.getTodoCount();

const CATCHUPS_BUTTON_ID = "catchups-button";

export const CatchupTodosButton: FC<CatchupTodosButtonPropsI> = ({
  className,
  isLoading: isUpdateTriggerActive,
}) => {
  const [{ data, isLoading }, fetchCount] = useApi({
    apiFactory: glencocoClientAPI,
    fetcher: todoCountFetcher,
  });

  const { count = 0 } = data || {};

  useEffect(() => {
    if (isUpdateTriggerActive) {
      void fetchCount();
    }
  }, [isUpdateTriggerActive]);

  if (count === 0) {
    return null;
  }

  return (
    <>
      <Button
        variant="outline"
        style={
          {
            "--pulse-alt-start-color": "rgba(200,200,200)",
            "--pulse-alt-end-color": "transparent",
          } as CSSProperties
        }
        className={clsxMerge(
          "min-w-[120px] transition-all",
          {
            "pointer-events-none !bg-[#2DA831] !text-black":
              !isLoading && count === 0,
            "animate-pulse-alt": !isLoading && count > 0,
          },
          className
        )}
      >
        <a id={CATCHUPS_BUTTON_ID} href={Router.session()}>
          {isLoading && <LoadingSpinner className="text-black" />}

          {!isLoading && (
            <>
              {count > 0 && (
                <div className="flex animate-fadein items-center gap-2">
                  <CalendarCheck className="w-4" />
                  <span>Today’s to-dos</span>
                  <span className="ae-typography-detail1 badge rounded-[4px] border-none bg-[#F88167] text-white">
                    {count}
                  </span>
                </div>
              )}

              {count === 0 && (
                <div className="flex animate-fadein items-center">
                  <CircleCheck className="w-4" />
                  <span className="ml-2">To-do list completed</span>
                </div>
              )}
            </>
          )}
        </a>
      </Button>
    </>
  );
};
