import { TopNavConditionalComponents } from "@/components/navigation/primary/top-nav-conditional-components";

export const DashboardPageHeader = () => {
  return (
    <div className="w-full">
      <div className="flex items-center justify-between">
        <div className="ae-typography-h2">Home</div>
        <TopNavConditionalComponents />
      </div>

      <div className="mt-3 h-[1px] w-[80px] bg-[#666666]"></div>
    </div>
  );
};
