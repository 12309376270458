import { FC, useMemo } from "react";
import take from "lodash/take";
import Link from "next/link";

import { clsxMerge } from "shared/lib/helpers";
import { useApi } from "shared/lib/hooks";
import { CampaignCard } from "shared/ui";
import { CAMPAIGN_CARD_TYPES } from "shared/ui/cards/campaign-card";
import { PropsWithClassNameI } from "shared/lib/interfaces/ui";

import { APII, glencocoClientAPI } from "@/api/glencoco";
import { UserDashboardSection } from "@/modules/dashboard-user/shared/section";
import Router from "@/helpers/router";
import { LoadingComponent } from "@/modules/loading";

const campaignsFetcher = (API: APII) => API.getDashboardCampaigns();

export const DashboardUserCampaignHighlightsSection: FC<
  PropsWithClassNameI
> = ({ className }) => {
  const [{ data, isLoading }] = useApi({
    apiFactory: glencocoClientAPI,
    fetcher: campaignsFetcher,
  });

  const campaigns = useMemo(() => take(data?.campaigns, 3), [data?.campaigns]);

  return (
    <UserDashboardSection className={clsxMerge(className)}>
      <div className="mb-5 flex items-center justify-between">
        <h5 className="brand-typography-h5">Campaigns Highlights 🔥</h5>

        <div className="max-w-[50%]">
          <p className="typography-body-5">
            💡Tip: You can view campaign stats by chatting with us in the bottom
            right chat window and clicking “Explore Campaigns”.
          </p>
        </div>

        <div className="flex flex-col items-end gap-y-2">
          <Link
            href={Router.marketplace()}
            className="brand-typography-body3 ae-link font-semibold"
          >
            Discover More Campaigns
          </Link>

          <Link
            href={Router.myCampaigns()}
            className="brand-typography-body3 ae-link font-semibold"
          >
            View Campaigns You’ve Enrolled In
          </Link>
        </div>
      </div>

      {isLoading && <LoadingComponent className="h-[330px]" delay={300} />}

      {!isLoading && (
        <div className="grid grid-cols-3 grid-rows-1 gap-x-3">
          {campaigns?.map((c) => (
            <Link
              key={c?.id}
              className="block"
              href={Router.marketplaceCampaign(c.id)}
            >
              <CampaignCard
                type={CAMPAIGN_CARD_TYPES.HIGHLIGHTS}
                {...c}
                containerClassName="h-full"
                className={clsxMerge(
                  "border-[0.5px] border-[#C2C2C2]",
                  "btn-free h-full",
                  "duration-500 animate-in fade-in"
                )}
              />
            </Link>
          ))}
        </div>
      )}
    </UserDashboardSection>
  );
};
